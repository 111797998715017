<template>
    <div>
      <!-- Start Header Area -->
      <Header />
      <!-- End Header Area -->

    <!-- Start Slider Area  -->
    <div class="slider-wrapper" id="home">
      <div
        class="slide designer-portfolio slider-style-3  d-flex align-center justify-center bg_color--5 rn-slider-height"
      >
        <Slider />
      </div>
    </div>
    <!-- End Slider Area  -->
  
    <!-- Start About Area  -->
    <div class="about-area pt--50 bg_color--5" id="about">
        <div class="about-wrapper">
            <v-container>
            <About>
                <!-- <img
                slot="thum-img"
                class="w-100"
                src="../../assets/images/about/about-7.jpg"
                alt="About Images"
                /> -->
            </About>
            </v-container>
        </div>
    </div>
    <!-- Start About Area  -->
  
    <!-- Start Portfolio Area -->
    <div class="service-area ptb--120 bg_color--5" id="problem">
      <v-container>
        <v-row class="mb--30">
          <v-col cols="12">
            <div class="text-center section-title">
                <span class="subtitle">Helft uns, Lösungen zu finden</span>
                <h2 class="heading-title">Unsere Probleme</h2>
                <p class="description">
                  Vielen Dank für eure Einreichungen! 
                  <br />
                  Die von euch eingereichten Probleme haben wir gebündelt. Nun gilt es effiziente Lösungskonzepte für die Problembündel zu entwickeln.
                  <br />
                  Entwickelt im Team oder alleine innovative und effiziente Lösungsansätze und reicht diese ein.
                  <br />
                  Eine Jury wählt prämiert die Lösungskonzepte anschließend.
                </p>

              <Problems />

            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
      <!-- End Portfolio Area -->

    <!-- Start Mission Area -->
    <div class="service-area ptb--20 bg_color--5" id="mission">
      <v-container>
        <v-row class="mt--50">
          <v-col cols="12">
            <div class="text-center section-title">
              <span class="subtitle">Die Zukunft gestalten, heute</span>
              <h2 class="heading-title">Unsere Mission</h2>
              <p class="description">
                Unsere Mission ist es, das Leben an der Hochschule Trier durch innovative Lösungsansätze zu verbessern. 
                <br />
                Wir möchten eine Plattform bieten, auf der Studierende ihre Anliegen und Probleme mitteilen können, die direkt mit der Hochschule zusammenhängen.
                Durch eure aktive Beteiligung wollen wir das Leben an der Hochschule verbessern! 
                <br />
                <br />
                Unser Ziel ist es, eine unterstützende Gemeinschaft zu schaffen, in der Studierende füreinander da sind und gemeinsam Lösungen entwickeln.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Mission Area -->
  
      <!-- Start Contact Area  -->
      <!--
      <div class="rn-contact-area ptb--120 bg_color--5" id="contact">
        <div class="contact-form--1">
          <v-container>
            <Contact>
            </Contact>
          </v-container>
        </div>
      </div>
      -->
      <!-- End Contact Area  -->

    <!-- Start Future Area -->
     <div class="service-area pt--20 pb--50 bg_color--5" id="future">
      <v-container>
        <v-row class="mb--30 mt--50">
          <v-col cols="12">
            <div class="text-center section-title">
              <span class="subtitle">Seid gespannt auf die nächste Phase</span>
              <h2 class="heading-title">Wie geht es weiter?</h2>
              <p class="description">
                Nachdem wir eine Vielzahl von Problemen gesammelt haben, werden wir eine Auswahl treffen und uns auf die Top 3 konzentrieren. Für jedes dieser Probleme starten wir eine aktive Challenge, bei der ihr Lösungen einreichen könnt. Dabei sind alle Studierenden eingeladen, sich zu beteiligen und gemeinsam Lösungen zu entwickeln.
                <br />
                <br />
                Als Ansporn werden die besten Lösungsansätze prämiert! Wir werden eine Belohnung an die Studierenden bzw. die Teams zu vergeben, deren Lösungen am effektivsten und nachhaltigsten dazu beitragen, das studentische Leben an der Hochschule Trier zu verbessern. Ein Leitfaden für das Einreichen der Lösung sowie Teilnahmebedingungen werden demnächst auf unserer Webseite zu sehen sein.
                <br />
                <br />
                Wir glauben daran, dass durch gemeinsame Anstrengungen und kreative Ideen wir dazu beitragen können, eine positivere und unterstützendere Umgebung für alle Studierenden an der Hochschule Trier zu schaffen. Dabei wird Ella, unser Maskottchen, den ganzen Prozess begleiten. 
                <br />
                <br />
                Falls ihr noch Fragen oder Anregungen habt könnt ihr uns unter 
                <a href="mailto:hello@ellavate.study">hello@ellavate.study </a>
                oder über unsere Instagram Seite 
                <a href="https://www.instagram.com/ellavate.study/">„ellavate.study“</a>
                erreichen!
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Future Area -->
  
      <!-- Start Footer Area  -->
      <Footer />
      <!-- End Footer Area  -->
    </div>
  </template>
  
  <script>
    import Header from "../../components/ellavate/Header";
    import Slider from "../../components/ellavate/Slider";
    import About from "../../components/ellavate/About";
    import Contact from "../../components/ellavate/Contact";
    import Footer from "../../components/ellavate/Footer";
    import Problems from "../../components/ellavate/Problems.vue"

    import axios from "axios";


    // import PortfolioTwo from "../../components/portfolio/PortfolioFour";
    // import PricingPlan from "../../components/pricing-plan/PricingPlan";
  
    export default {
      components: {
        Header,
        Slider,
        About,
        Contact,
        Footer,
        Problems,

        // PortfolioTwo,
        // PricingPlan,
        
      },
      mounted() {
        const queryParam = this.$route.query.ref;
        if (queryParam) {

          axios.post(
            'https://api.ellavate.study/track',
            { param: queryParam },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          ).then(response => {
            console.log('Ref-Code send successfully:', response.data);
          })
          .catch(error => {
            console.error('Error while sending Ref-Code:', error);
          });
        }
      }
    };
  </script>
  
  <style lang="scss">
    .feather-menu {
      color: #1d1d24;
    }
  </style>
  